import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black py-6 text-center text-white">
      <div className="container mx-auto">
        Copyright &copy; 2024. All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
