import React, { useContext, useState } from 'react';
import { HouseContext } from './HouseContext';
import { Menu } from '@headlessui/react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { motion } from 'framer-motion';

const TransactionTypeDropdown = () => {
  const { transactionType, setTransactionType } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  const transactionTypes = ['All', 'Rent', 'Sell'];

  const handleTransactionTypeChange = (type) => {
    setTransactionType(type);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <Menu as='div' className='dropdown relative'>
      <Menu.Button onClick={() => setIsOpen(!isOpen)} className='dropdown-btn w-full  w-48 text-left ' style={{marginLeft: "10px"}}>
        <div>
          <div className='text-[15px] font-medium leading-tight'>{transactionType || 'All'}</div>
          <div className='text-[13px]'>Select  type</div>
        </div>
        {isOpen ? <RiArrowUpSLine className='dropdown-icon-secondary' /> : <RiArrowDownSLine className='dropdown-icon-secondary' />}
      </Menu.Button>
      <Menu.Items as={motion.ul} className='dropdown-menu' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        {transactionTypes.map((type, index) => (
          <Menu.Item onClick={() => handleTransactionTypeChange(type)} className='cursor-pointer hover:text-blue-700 transition' as='li' key={index}>
            {type}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default TransactionTypeDropdown;
