
import Sidebar from './sidebar';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import AddProperty from './components/AddProperty';
// import PropertyList from './components/PropertyList';
// import Inquiry from './components/Inquiry';

function Dashboard() {
  return (
    <div>
      {/* <Navbar /> */}
      <Sidebar />
        
     
    </div>
  );
}

export default Dashboard;
